@import url('https://fonts.googleapis.com/css?family=Roboto:400,600,700');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  /* background-color:rgb(212, 212, 242); */
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p,
div, header, section {
  margin: 0;
}

input, label{
  display: block;
}

.table-view {
  display: table;
  width: 100%;
  border-spacing: 0 10px;
}
.table-view .table-header {
  display: table-header-group;
  background-color: #D5FFD5;
  font-weight: bold;
}
.table-view .table-body {
  display: table-row-group;
}
.table-view .table-row {
  display: table-row;
}
.table-view .table-body .table-row {
  background-color: #fff;
  border-top: 10px solid transparent;
}
.table-view .table-header .th,
.table-view .table-body .table-row .td {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
}
.table-view .table-row .th:first-child {
  width: 40%;
}
.table-view .table-row .th:last-child button {
  float: right;
}
.table-view .table-row .td:last-child {
  text-align: right;
}

button.add-button {
  border: 1px solid #05AAE9;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
button.add-button svg {
  font-size: 1.5rem;
  color: #05AAE9;
}

.actions button {
  background-color: #05AAE945;
  border: none;
  padding: 8px 8px 4px;
}
.actions button.del {
  background-color: #FF121B45;
}
.actions button svg {
  color:#05AAE9;
  width: 20px;
  height: auto;
}


button{
  border: none;
  outline: none;
}

.h-100{
  height: 100%;
}

.w-30{
  width: 32%;
}

.devview{
  border:2px solid red;
}
.devview2{
  border:2px solid blue;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: auto;
  transform: translate(-50%, -50%);
}

.center-top {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 70%;
  height: 200px;
  transform: translate(-50%, -50%);
}

.white-background{
  background-color: white;
}

.lightgray-background{
  background-color: rgb(240, 238, 238);
}
.center-40 {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 40%;
  height: 30%;
  transform: translate(-50%, -50%);
  /* border: 3px solid green; */
}

.login-border-radius{
  border-radius: 5px;
}
input {
  outline: 0;
  background-color: white;
  border-width: 0 0 1px;
  border-color: silver
}
input:focus {
  border-color: #5c7a8c
}
.circle-pic{
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.circle-form-pic{
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.circle-formdata-pic{
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.file-input {
  position: relative;
  display: inline-block;
}

.file-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-input span {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.circle-pic-details{
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.smallCard{
  /* min-width: 344px; */
}

.details-image{
  margin:50px 0px 50px 0px;
}

.table-pic{
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  /* margin: 20px auto; */
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #333;
}

.dropdown-content:hover {
  background-color: #f1f1f1;
}

.dropdown-container:hover .dropdown-menu {
  display: block;
}

.login-cont{
  border: 1px solid silver;
  border-radius: 5px;
}

.background-gradient{
  background: linear-gradient(to right, #89cff0, #5c7a8c);
  
}


.flex-end{
  justify-content: end;
}

.pointer{
  cursor: pointer;
}

.table-container {
  overflow-x:scroll !important;;
}

.flex-basis-60{
  flex-basis: 60%;
}
.flex-basis-40{
  flex-basis: 40%;
}

.login-form{
  width: 300px;
  height: 200px;
}

section {
  width: 100%;
  margin: 0;
}

.padded-bottom{
  border-bottom: 3px solid gold;
}

.p-r {
  position: relative!important;
}

.no-padding {
  padding: 0!important;
}
.p-10 {
  padding: 10px!important;
}
.p-b-10 {
  padding-bottom: 10px!important;
}
.p-20 {
  padding: 20px!important;
}
.p-40 {
  padding: 40px!important;
}
.p-t-40 {
  padding-top: 40px!important;
}
.p-r-5 {
  padding-right: 5px!important;
}
.p-r-10 {
  padding-right: 10px!important;
}
.p-l-5 {
  padding-left: 5px!important;
}

.no-margin {
  margin: 0!important;
}
.m-10{
  margin: 10px;
}
.m-20{
  margin: 20px;
}
.m-l-5 {
  margin-left: 5px!important;
}
.m-l-10 {
  margin-left: 10px!important;
}
.m-l-20 {
  margin-left: 20px!important;
}
.m-r-5 {
  margin-right: 5px!important;
}
.m-r-10 {
  margin-right: 10px!important;
}
.m-r-20 {
  margin-right: 20px!important;
}
.m-r-30{
  margin-right: 30px;
}
.m-r-40{
  margin-right: 40px;
}
.m-r-50{
  margin-right: 50px;
}
.m-r-60{
  margin-right: 60px;
}
.m-r-70{
  margin-right: 70px;
}
.m-r-80{
  margin-right: 80px;
}




.m-t-10 {
  margin-top: 10px!important;
}
.m-t-20 {
  margin-top: 20px!important;
}
.m-t-15{
  margin-top: 15px;
}
.m-t--20 {
  margin-top: -20px!important;
}
.m-t-40 {
  margin-top: 40px!important;
}
.m-t-5 {
  margin-top: 5px!important;
}
.m-b-5 {
  margin-bottom: 5px!important;
}
.m-b-10 {
  margin-bottom: 10px!important;
}
.m-b-20 {
  margin-bottom: 20px!important;
}
.m-b-40 {
  margin-bottom: 40px!important;
}
.m-b-50 {
  margin-bottom: 50px!important;
}
.m-b-100 {
  margin-bottom: 50px!important;
}

.w-fit{
width: fit-content;
}

.no-border {
  border: none!important;
}
.no-wrap {
  flex-wrap: nowrap!important;
}
.wrap {
  flex-wrap: wrap!important;
}
.flex {
  display: flex!important;
}
.flex-direction-v{
  flex-direction: column;
}
.flex-h-center {
  justify-content: center!important;
}
.flex-v-center {
  align-items: center!important;
}
.flex-basis-100{
  flex-basis:100%;
}
.pointer {
  cursor: pointer!important;
}
.underline {
  text-decoration: underline!important;
}
.underline-text{
  border-bottom: 3px solid gold;
}
.center-text {
  text-align: center!important;
}
.right-text {
  text-align: right!important;
}
.space-between {
  justify-content: space-between!important;
}
.float-right {
  float: right!important;
}
.inline-block {
  display: inline-block!important;
}
.bold-text {
  font-weight: bold!important;
}

.max-w-400 {
  max-width: 400px!important;
}

.min-w-400 {
  min-width: 400px!important;
}

.max-h-400 {
  height: 400px!important;
}
.blue-bottom{
  border-bottom: 4px solid #05AAE9;
}
.col-1, .col-2, .col-3, .col-4,
.col-5, .col-6, .col-half, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6, .col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12, .w-100 {
  width: 100%!important;
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.w-80{
  width: 80%;
}

.h-80{
  height: 90%;
}

.w-60{
  width:60%;
}

.ant-table-wrapper {
  overflow-x: auto;
}


.form-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
}
.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url('../images/banner.png');
  background-color: #1C811C55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}

.color-white{
  color: white;
} 

.color-black{
  color: black;
}

.color-blue{
  background-color: rgb(157, 228, 249);
}

.color-red{
  background-color: rgb(191, 39, 39);
}

.card-radius{
  border-radius: 5px;
}

.green-outline{
  border: 1px solid #1C811C;
  background-color: rgb(98, 239, 98);
  border-radius: 3px;
  padding: 0px 15px;
  margin-right: 8px;
}

.pink-outline{
  border: 1px solid rgb(247, 98, 123);
  background-color: pink;
  border-radius: 3px;
  padding: 0px 15px;
  margin-right: 8px;
}

.amber-outline{
  border: 1px solid rgb(238, 238, 67);
  background-color: rgb(244, 244, 131);
  border-radius: 3px;
  padding: 0px 15px;
  margin-right: 8px;
}

.lightblue-outline{
  border: 1px solid rgb(53, 53, 183);
  background-color: lightblue;
  border-radius: 3px;
  padding: 0px 15px;
}

.flex-basis-15{
  flex-basis: 15%;
}

.flex-basis-30{
  flex-basis: 30%;
}

.flex-basis-45{
  flex-basis: 45%;
}

.flex-basis-60{
  flex-basis: 60%;
}

.flex-basis-75{
  flex-basis: 75%;
}

.flex-basis-90{
  flex-basis: 90%;
}

.flex-basis-100{
  flex-basis: 100%;
}

.flex-basis-5{
  flex-basis: 5%;
}

.flex-basis-10{
  flex-basis: 10%;
}

.flex-basis-20{
  flex-basis: 20%;
}

.flex-basis-30{
  flex-basis: 30%;
}

.flex-basis-40{
  flex-basis: 40%;
}

.flex-basis-50{
  flex-basis: 50%;
}

.flex-basis-60{
  flex-basis: 60%;
}

.flex-basis-70{
  flex-basis: 70%;
}

.flex-basis-80{
  flex-basis: 80%;
} 

.flex-basis-90{
  flex-basis: 90%;
}



.scoreSize{
  font-size:xx-large;
}

.square-labels{
  padding: 4px 6px;
  border: 1px solid #05AAE9;
  background-color: white;
  cursor: pointer;
}

.square-labels:hover{
  background-color: #05AAE9;
  color: white;
}

.square-labels:active{
  background-color: #004577;
  color: white;
}

.active-square{
  background-color: #05AAE9;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.active-square:active{
  background-color: #004577;
  color: white;
}

.grey-bg{
  background-color: #f5f5f5;

}

.dev-border{
  border: 1px solid #d70505;
}

.nameSize{
  font-size:large;

}

div.login .coat-of-arm {
  border-right: 1px solid #C6C6C6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017D34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017D3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #FFFFFF;
  box-shadow: 5px 3px 22px #958E8E29;
  border: 1px solid #C6C6C6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1C811C;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.banner-s{
 
}

.ant-collapse-item{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ant-collapse-item:hover{
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Optional: adds a slight lift effect */
}

.headline {
  align-items: center;
  border-bottom: 1px solid #C6C6C6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1C811C1F;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #C6C6C6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1C811C;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}
