body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
  color: #788195
}

body.mobile .page-sidebar .sidebar-menu .menu-items li.active>a,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open>a {
  color: #000
}

.page-sidebar {
  width: 260px;
  background-color: #fff;
  z-index: 1000;
  left: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(.05, .74, .27, .99);
  -moz-transition: -moz-transform 400ms cubic-bezier(.05, .74, .27, .99);
  -o-transition: -o-transform 400ms cubic-bezier(.05, .74, .27, .99);
  transition: transform 400ms cubic-bezier(.05, .74, .27, .99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000
}

.page-sidebar a,
.page-sidebar button {
  color: #788195
}

.page-sidebar a:hover,
.page-sidebar button:hover,
.page-sidebar a:active,
.page-sidebar button:active {
  color: #000;
  background-color: transparent!important
}

.page-sidebar a:hover:focus,
.page-sidebar button:hover:focus,
.page-sidebar a:active:focus,
.page-sidebar button:active:focus {
  color: #000
}

.page-sidebar a:visited,
.page-sidebar button:visited,
.page-sidebar a:focus,
.page-sidebar button:focus {
  color: #788195
}

.page-sidebar button.sidebar-slide-toggle.active {
  color: #788195
}

.page-sidebar button.sidebar-slide-toggle.active:focus {
  background: 0 0
}

.page-sidebar .sidebar-header {
  display: block;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  color: #232730;
  width: 100%;
  padding: 0 20px;
  padding-left: 40%;
  padding-top: 10px;
  clear: both;
  z-index: 10;
  position: relative;
}

.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  -webkit-transition: -webkit-transform .4s cubic-bezier(.05, .74, .27, .99);
  transition: transform .4s cubic-bezier(.05, .74, .27, .99);
  -webkit-backface-visibility: hidden
}

.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  -webkit-transition: all .12s ease;
  transition: all .12s ease
}

.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.page-sidebar .close-sidebar {
  position: absolute;
  right: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1
}

.page-sidebar .close-sidebar>i {
  color: rgba(255, 255, 255, .57)
}

.page-sidebar .sidebar-overlay-slide {
  width: 100%;
  height: 100%;
  background-color: #272b35;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px
}

.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  -webkit-transition: all .2s ease;
  transition: all .2s ease
}

.page-sidebar .sidebar-overlay-slide.from-top.show {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%)
}

.page-sidebar .sidebar-menu {
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
  border-right: 1px solid #F5F7F9;
}

.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
  background-color: #0aa699!important
}

.page-sidebar .sidebar-menu .menu-items {
  list-style: none;
  margin: 0;
  padding: 30px 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%
}

.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar {
  background: lightyellow;
  width: 7px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.page-sidebar .sidebar-menu .menu-items li:hover>a,
.page-sidebar .sidebar-menu .menu-items li.open>a,
.page-sidebar .sidebar-menu .menu-items li.active>a {
  color: #000
}

.page-sidebar .sidebar-menu .menu-items li>a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 77%;
  text-decoration: none;
}

.page-sidebar .sidebar-menu .menu-items>li {
  position: relative;
  display: flex;
  padding: 5px 10px 5px 20px;
  align-items: center;
}
.page-sidebar .sidebar-menu .menu-items>li.active,
.page-sidebar .sidebar-menu .menu-items>li:hover {
  background-color: #F5F7F9;
}

.page-sidebar .sidebar-menu .menu-items>li.active:before,
.page-sidebar .sidebar-menu .menu-items>li:hover:before {
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #017D34;
  content: '';
  left: 0;
}

.page-sidebar .sidebar-menu .menu-items>li .icon {
  width: 20px;
  height: auto;
}
.page-sidebar .sidebar-menu .menu-items>li>a {
  font-family: Arial, sans-serif;
  display: inline-block;
  padding-left: 10px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  clear: both
}

.page-sidebar .sidebar-menu .menu-items>li>svg.open {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.page-sidebar .sidebar-menu .menu-items>li>a>.title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 65%
}

.page-sidebar .sidebar-menu .menu-items>li.active>ul.sub-menu {
  display: block
}

.page-sidebar .sidebar-menu ul.sub-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-sidebar .sidebar-menu ul.sub-menu>li {
  background: 0 0;
  padding: 10px 0 10px 45px;
  margin-top: 1px;
}

.page-sidebar .sidebar-menu ul.sub-menu>li.active a {
  font-weight: bold;
}

.page-sidebar .sidebar-menu .muted {
  color: #576775;
  opacity: .45
}

/* 
@media only screen and (max-width:1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none
  }
}

@media only screen and (min-width:980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none!important;
    -webkit-transform: none!important;
    -ms-transform: none!important
  }
  body.menu-pin [data-toggle-pin=sidebar]>i:before {
    content: "\f192"
  }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0)!important;
    -webkit-transform: translate(210px, 0)!important;
    -ms-transform: translate(210px, 0)!important
  }
  body.menu-pin .page-sidebar {
    width: 250px
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px)
  }
  body.menu-behind .page-sidebar {
    z-index: 799
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none!important;
    -webkit-transform: none!important
  }
}

@media(max-width:991px) {
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0!important;
    -webkit-transform: translate3d(0, 0px, 0px)!important;
    transform: translate3d(0, 0px, 0px)!important
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 30px 0 36px
  }
  .page-sidebar {
    display: none;
    z-index: auto
  }
  .page-sidebar.visible {
    display: block
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0!important;
    width: 260px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul>li>a>.badge {
    display: inline-block
  }
}

@media(min-width:768px) and (max-width:1024px) and (orientation:landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 27px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 27px 0 31px
  }
}

@media(max-width:767px) {
  .page-sidebar .sidebar-header {
    padding: 0 12px
  }
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 20px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 25px 0 28px
  }
}

@media(max-width:480px) {
  body .header .header-inner .toggle-secondary-sidebar {
    font-size: 16px;
    top: 12px
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px
  }
} */
